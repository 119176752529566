angular.module('relayHealth').controller('completedAppointmentsCtrl', [
  '$rootScope',
  '$scope',
  '$http',
  'API_BASE_URL',
  'toaster',
  '$state',
  'SweetAlert',
  '$filter',
  'loadingScreenFactory',
  '$stateParams',
  '$location',
  'Pubnub',
  'getMarketSegments',
  'isOCCMRole',
  '$q',
  '$timeout',
  'storageManager',
  'requestingOrganizationFactory',
  function completedAppointmentsCtrl(
    $rootScope,
    $scope,
    $http,
    API_BASE_URL,
    toaster,
    $state,
    SweetAlert,
    $filter,
    loadingScreenFactory,
    $stateParams,
    $location,
    Pubnub,
    getMarketSegments,
    isOCCMRole,
    $q,
    $timeout,
    storageManager,
    requestingOrganizationFactory,
  ) {
    function toasterError(message) {
      toaster.pop({
        type: 'error',
        title: message || 'Could not fetch users List',
        showCloseButton: true,
        timeout: 10000,
      });
    }
    const {
      permissions: { permissionsConstants },
      common: { constants: { userRoles: ROLES, providerIdMapping: providerId } },
      appointment: { status: appointmentStatus },
      ride: { status: rideStatus },
    } = relaylib;
    $scope.moment = moment;
    $scope.providerId = providerId;
    $scope.permissionsConstants = permissionsConstants;
    $scope.provider = { providerNameList: [] };
    let showVarianceTab = false;
    $scope.isClientOrg = true;
    $scope.disableOrgList = false;
    const { storage } = storageManager;
    $scope.user_roles = ROLES;
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const currentState = $state.current.name;
    const visibleChoicesCount = 20;
    const requestingOrganizationsVisibleCount = 20;

    if (
      (
        userDetails.role
        && userDetails.role === 'mastersuperadmin'
      )
      || $filter('permission')(permissionsConstants.SHOW_VARIANCE_TAB_COMPLETED_APPTS)
    ) {
      showVarianceTab = true;
    }

    if ($filter('isMasterOrg')(userDetails.organisation.org_level)) {
      $scope.isClientOrg = false;
    }

    $scope.isOCCMRole = isOCCMRole() || $filter('permission')(permissionsConstants.MARKET_SEGMENT_READ);

    const orgUsers = {};
    $scope.getUsersList = function getUsersList(offset, limit, tabName) {
      const getUsersUrl = 'getUsersByOrgIdForDropBox/';

      if (!($scope.tabs[tabName] && _.get($scope.tabs[tabName], 'completed.organization.id'))) {
        return $q.resolve({ data: [] });
      }
      if (Object.prototype.hasOwnProperty.call(orgUsers, $scope.tabs[tabName].completed.organization.id)) {
        return $q.resolve({
          data: orgUsers[$scope.tabs[tabName].completed.organization.id],
          count: orgUsers[$scope.tabs[tabName].completed.organization.id].length,
        });
      }
      // Do nothing if data not found in cache

      const selectedOrg = $scope.tabs[tabName].completed.organization.id;
      return $http
        .get(`${API_BASE_URL}${getUsersUrl}${selectedOrg}/${offset}/${limit}`)
        .then(
          (resp) => {
            let userList = [];
            const { data } = resp;
            if (data.success) {
              userList = data.userList.map(user => ({ name: user.full_name, userId: user.ride_requester_id }));
            } else {
              toasterError(data.message);
            }
            return {
              data: userList,
              count: Number(data.totalCount),
            };
          },
          (data) => {
            toasterError(data.message);
          },
        );
    };

    $scope.cacheUserList = function cacheUserList(data, tabName) {
      if (data && data.length > 0 && $scope.tabs[tabName] && _.get($scope.tabs[tabName], 'completed.organization.id')) {
        orgUsers[$scope.tabs[tabName].completed.organization.id] = data;
      }
    };

    $scope.tabs = {
      all: {
        heading: 'All Trips',
        active: true,
        url: 'appointments/getApptListByApptStatus/Completed/updatedAt DESC/',
        pagination: {
          totalItems: 0,
          currentPage: 1,
          lastItem: 0,
          itemsPerPage: 20,
          data: [],
        },
        show: true,
        firstTimeLoad: true,
        searchFormQuery: {
          dateRange: {},
        },
        completed: {
          dateRange: {},
          userList: [],
        },
        selectedOrg: '',
        showLoader: false,
        invoiceStatusList: [
          'Invoice Pending',
          'Invoice Finalized',
          'Invoice Blocked',
        ],
        statusList: [
          rideStatus.RETRO_QUEUED.status,
          appointmentStatus.PATIENT_NO_SHOW.status,
          appointmentStatus.APPOINTMENT_COMPLETED.status,
          appointmentStatus.APPOINTMENT_CANCELED.status,
          appointmentStatus.APPOINTMENT_REJECTED.status,
          rideStatus.VALIDATION_FAILED.status,
          rideStatus.PENDING_SUBMISSION.status,
          rideStatus.PATIENT_DELETED.status,
          rideStatus.PENDING_DISPOSITION.status,
        ],
        userList: [],
        totalUsersRecords: 0,
      },
      rides: {
        heading: 'Vendor Rides',
        active: false,
        url: 'appointments/getApptListByApptStatus/Completed/updatedAt DESC/',
        pagination: {
          totalItems: 0,
          currentPage: 1,
          lastItem: 0,
          itemsPerPage: 20,
          data: [],
        },
        show: true,
        firstTimeLoad: true,
        searchFormQuery: {
          dateRange: {},
        },
        completed: {
          dateRange: {},
          userList: [],
        },
        selectedOrg: '',
        showLoader: false,
        invoiceStatusList: [
          'Invoice Pending',
          'Invoice Finalized',
          'Invoice Blocked',
        ],
        statusList: [
          rideStatus.RETRO_QUEUED.status,
          appointmentStatus.PATIENT_NO_SHOW.status,
          appointmentStatus.APPOINTMENT_COMPLETED.status,
          appointmentStatus.APPOINTMENT_CANCELED.status,
          appointmentStatus.APPOINTMENT_REJECTED.status,
          rideStatus.VALIDATION_FAILED.status,
          rideStatus.PENDING_DISPOSITION.status,
        ],
        userList: [],
        totalUsersRecords: 0,
      },
      pr: {
        heading: 'Mileage Tracker',
        active: false,
        url: 'appointments/getApptListByApptStatus/Completed/updatedAt DESC/',
        pagination: {
          totalItems: 0,
          currentPage: 1,
          lastItem: 0,
          itemsPerPage: 20,
          data: [],
        },
        show: true,
        firstTimeLoad: true,
        searchFormQuery: {
          dateRange: {},
        },
        completed: {
          dateRange: {},
          userList: [],
        },
        selectedOrg: '',
        showLoader: false,
        statusList: [
          appointmentStatus.APPOINTMENT_COMPLETED.status,
          appointmentStatus.APPOINTMENT_CANCELED.status,
          rideStatus.PENDING_SUBMISSION.status,
          rideStatus.PATIENT_DELETED.status,
          rideStatus.PENDING_DISPOSITION.status,
        ],
        userList: [],
        totalUsersRecords: 0,
      },
    };

    $scope.tab_headings = {
      all: 'all',
      rides: 'rides',
      pr: 'pr',
    };

    function writeSearchFilter(tab) {
      const prevSearchValues = storage.getDataByKey('keepFilterData', currentState);
      if (!prevSearchValues) {
        $scope.searchButtonClicked(tab);
        return;
      }
      const tabToSearchOn = prevSearchValues.tab;
      const prevForm = prevSearchValues.data;
      if (prevForm.providerName && prevForm.providerId) {
        $scope.tabs[tabToSearchOn].completed.providerName = {
          name: prevForm.providerName,
          providerId: prevForm.providerId,
        };
      }
      $scope.tabs[tabToSearchOn].completed.patientName = prevForm.patientName;
      $scope.tabs[tabToSearchOn].pagination.currentPage = prevSearchValues.currentPage;
      $scope.tabs[tabToSearchOn].completed.patientPhoneNumber = prevForm.patientPhoneNumber;
      $scope.tabs[tabToSearchOn].completed.organization = prevForm.organization;
      $scope.tabs[tabToSearchOn].completed.dateRange = prevForm.dateRange || {
        startDate: null,
        endDate: null,
      };
      $scope.tabs[tabToSearchOn].completed.market_segment_id = prevForm.market_segment_id;
      $scope.tabs[tabToSearchOn].completed.referenceNo = prevForm.referenceNo;
      $scope.tabs[tabToSearchOn].completed.appointmentType = prevForm.appointmentType;
      $scope.tabs[tabToSearchOn].completed.status = prevForm.status;
      $scope.tabs[tabToSearchOn].completed.invoiceStatus = prevForm.invoiceStatus;
      $scope.tabs[tabToSearchOn].completed.rideType = prevForm.is_pr_ride ? 'Personal Reimbursement' : 'Vendor Rides';
      $scope.tabs[tabToSearchOn].completed.userList = prevForm.userList;
      $scope.tabs[tabToSearchOn].completed.orderId = prevForm.orderId;
      $scope.tabs[tabToSearchOn].completed.tripIdentifierType = prevForm.tripIdentifierType;
      $scope.tabs[tabToSearchOn].completed.masInvoiceNumber = prevForm.masInvoiceNumber;
      if ($scope.tabs[tabToSearchOn].completed.organization) {
        $scope.orgChanged($scope.tabs[tabToSearchOn].completed.organization.id, tabToSearchOn, true);
      }
      $scope.tabs[tabToSearchOn].completed.requestingOrganization = prevForm.requestingOrganization;
      $timeout(() => {
        $scope.searchButtonClicked(tabToSearchOn);
      });
    }

    if (showVarianceTab) {
      $scope.tabs.variance = {
        heading: 'Variance',
        active: false,
        url: 'appointments/getApptListByApptStatus/Completed/updatedAt DESC/',
        pagination: {
          totalItems: 0,
          currentPage: 1,
          lastItem: 0,
          itemsPerPage: 20,
          data: [],
        },
        show: true,
        firstTimeLoad: true,
        searchFormQuery: {
          dateRange: {},
        },
        completed: {
          dateRange: {},
          userList: [],
        },
        selectedOrg: '',
        showLoader: false,
        invoiceStatusList: [
          'Invoice Pending',
          'Invoice Finalized',
          'Invoice Blocked',
        ],
        statusList: [
          rideStatus.RETRO_QUEUED.status,
          appointmentStatus.PATIENT_NO_SHOW.status,
          appointmentStatus.APPOINTMENT_COMPLETED.status,
          appointmentStatus.APPOINTMENT_CANCELED.status,
          appointmentStatus.APPOINTMENT_REJECTED.status,
          rideStatus.VALIDATION_FAILED.status,
          rideStatus.PENDING_DISPOSITION.status,
        ],
        userList: [],
        totalUsersRecords: 0,
      };
    }
    $scope.dateRangeOptions = {
      parentEl: '#current-app #page-wrapper',
      format: 'YYYY-MM-DD',
      locale: {
        format: 'YYYY-MM-DD',
      },
      opens: 'left',
      eventHandlers: {
        'apply.daterangepicker': function daterangepickerEvent() {
          let activeTab;

          Object.keys($scope.tabs).forEach((tabKey) => {
            if ($scope.tabs[tabKey].active) {
              activeTab = tabKey;
            }
          });

          const elementId = `dateRange-${activeTab}`;
          const element = document.getElementById(elementId);
          if (element) {
            const dateRangeFilter = element.value;
            if (dateRangeFilter) {
              const dateRangeFilterArr = dateRangeFilter.split(' - ');
              $scope.tabs[activeTab].searchFormQuery.dateRange.startDate = `${dateRangeFilterArr[0]} 00:00:00`;
              $scope.tabs[activeTab].searchFormQuery.dateRange.endDate = `${dateRangeFilterArr[1]} 23:59:59`;
              $scope.tabs[activeTab].completed.dateRange = $scope.tabs[activeTab].searchFormQuery.dateRange;
            }
          }
        },
      },
    };

    $scope.marketSegmentList = [];

    getMarketSegments((err, data) => {
      if (!err) {
        $scope.marketSegmentList = data;
      } else {
        toaster.pop({
          type: 'error',
          title: err.msg || err,
          showCloseButton: true,
          timeout: 10000,
        });
      }
    });

    $scope.minExportDate = moment().subtract(2, 'months');
    $scope.maxExportDate = new Date().setHours(23, 59, 59);
    $scope.dateRangeExport = {
      startDate: moment().subtract(2, 'months').startOf('day'),
      endDate: new Date().setHours(23, 59, 59),
    };
    $scope.button_disabled = false;
    $scope.export_button = 'Export';
    $scope.exportRideTypes = { 1: 'All', 2: 'Vendor Rides', 3: 'Mileage Tracker' };
    $scope.export_type = '1';

    function getLatestProcessedExportRecords() {
      const data = {};
      if (userDetails.belongsToParentOrg) {
        data.params = { org_id: userDetails.organisation.id };
      }
      $http
        .get(`${API_BASE_URL}export/getLatestProcessedExportRecords/Completed Appointments`, data)
        .then((result) => {
          if (result.data.success) {
            $scope.exportList = result.data.data;
          }
        })
        .catch((err) => {
          toaster.pop({
            type: 'error',
            title: err,
            showCloseButton: true,
            timeout: 10000,
          });
        });
    }
    getLatestProcessedExportRecords();

    $scope.exportInDateRange = function exportInDateRange() {
      if ($scope.dateRangeExport) {
        const {
          key: activeTab,
        } = Object.keys($scope.tabs).find(key => $scope.tabs[key].active);

        let isPrRide;

        switch ($scope.export_type) {
          case '2':
            isPrRide = false;
            break;
          case '3':
            isPrRide = true;
            break;
          default:
        }

        const startDateString = $scope.moment($scope.dateRangeExport.startDate).format('YYYY-MM-DD');
        const endDateString = $scope.moment($scope.dateRangeExport.endDate).format('YYYY-MM-DD');

        const url = `${API_BASE_URL}saveExportFilters`;
        const data = {
          start_date: `${startDateString} 00:00:00`,
          end_date: `${endDateString} 23:59:59`,
          is_pr_ride: isPrRide,
          page: 'Completed Appointments',
        };
        $scope.button_disabled = true;
        $scope.export_button = 'Processing';
        const exportInDateRangeCall = $http({
          url,
          method: 'POST',
          data,
          headers: {
            'Content-type': 'application/json',
          },
        });
        exportInDateRangeCall.then(
          (result) => {
            $scope.button_disabled = false;
            $scope.export_button = 'Export';
            if (result.success === false) {
              // alert('failed');
            } else {
              toaster.pop({
                type: 'info',
                title: 'Please wait for your file.',
                showCloseButton: true,
                timeout: 10000,
              });
              getLatestProcessedExportRecords();
            }
          },
          (err) => {
            $scope.button_disabled = false;
            $scope.export_button = 'Export';
            $scope.tabs[activeTab].data = [];
            $scope.tabs[activeTab].pagination.totalItems = 0;
            toaster.pop({
              type: 'error',
              title: err.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          },
        );
      } else {
        toaster.pop({
          type: 'error',
          title: 'Choose valid date range',
          showCloseButton: true,
          timeout: 10000,
        });
      }
    };

    $scope.rideTypes = ['Vendor Rides', 'Personal Reimbursement'];
    $scope.appointmentTypes = [
      // "Customer Event", "OCCM Event/Office", "Airport", "Client Meeting", "Other"
      'Medical Appointment',
      'Legal Appointment',
      'Work',
      'Business Travel',
      'Personal Travel',
      'Shopping',
      'Entertainment',
      'General/Other',
    ];

    if ($stateParams) {
      let tabKey = 'all';
      if ($stateParams.activeTab) {
        tabKey = $stateParams.activeTab;
      }
      $location.search('activeTab', tabKey);

      Object.keys($scope.tabs).forEach((key) => {
        Object.assign($scope.tabs[key], {
          active: tabKey === key,
        });
      });

      Object.keys($stateParams).forEach((key) => {
        if (key === 'startDate' && $stateParams.startDate) {
          $scope.tabs[tabKey].searchFormQuery.dateRange.startDate = $stateParams.startDate;
          $scope.tabs[tabKey].completed.dateRange.startDate = $stateParams.startDate;
        } else if (key === 'endDate' && $stateParams.endDate) {
          $scope.tabs[tabKey].searchFormQuery.dateRange.endDate = $stateParams.endDate;
          $scope.tabs[tabKey].completed.dateRange.endDate = $stateParams.endDate;
        } else if (key === 'currentPage' && $stateParams.currentPage) {
          $scope.tabs[tabKey].pagination.currentPage = $stateParams.currentPage;
        } else if (key === 'organizationName' && $stateParams.organizationName) {
          $scope.tabs[tabKey].searchFormQuery.organization = {
            name: $stateParams.organizationName,
          };
          $scope.tabs[tabKey].completed.organization = {
            name: $stateParams.organizationName,
          };
        } else if (key === 'is_pr_ride') {
          if ($stateParams.is_pr_ride === 'true') {
            $scope.tabs[tabKey].searchFormQuery.rideType = 'Personal Reimbursement';
            $scope.tabs[tabKey].completed.rideType = 'Personal Reimbursement';
          } else if ($stateParams.is_pr_ride === 'false') {
            $scope.tabs[tabKey].searchFormQuery.rideType = 'Vendor Rides';
            $scope.tabs[tabKey].completed.rideType = 'Vendor Rides';
          }
        } else {
          $scope.tabs[tabKey].searchFormQuery[key] = $stateParams[key];
          $scope.tabs[tabKey].completed[key] = $stateParams[key];
        }
      });

      if (_.get($scope.tabs[tabKey], 'completed.organization.name')) {
        const timer = $timeout(() => {
          $scope.tabs[tabKey].reloadRequesters = true;
          $scope.tabs[tabKey].completed.userList = [];
        });
        timer.then(() => {
          $timeout.cancel(timer);
        });
      }
    }

    function setSearchFilters(tabKey) {
      const matchData = {};

      if ($scope.tabs[tabKey].searchFormQuery.organization) {
        matchData.organization = {
          name: $scope.tabs[tabKey].searchFormQuery.organization.name,
          id: $scope.tabs[tabKey].searchFormQuery.organization.id,
        };
      }

      if ($scope.tabs[tabKey].searchFormQuery.patientName) {
        matchData.patientName = $scope.tabs[tabKey].searchFormQuery.patientName.trim();
      }

      if ($scope.tabs[tabKey].searchFormQuery.market_segment_id) {
        matchData.market_segment_id = $scope.tabs[tabKey].searchFormQuery.market_segment_id;
      }

      if ($scope.tabs[tabKey].searchFormQuery.status) {
        matchData.status = $scope.tabs[tabKey].searchFormQuery.status;
      }

      if ($scope.tabs[tabKey].searchFormQuery.invoiceStatus) {
        matchData.invoiceStatus = $scope.tabs[tabKey].searchFormQuery.invoiceStatus;
      }

      if ($scope.tabs[tabKey].searchFormQuery.appointmentType) {
        matchData.appointmentType = $scope.tabs[tabKey].searchFormQuery.appointmentType;
      }

      if ($scope.tabs[tabKey].searchFormQuery.referenceNo) {
        matchData.referenceNo = $scope.tabs[tabKey].searchFormQuery.referenceNo;
      }

      if ($scope.tabs[tabKey].searchFormQuery.patientPhoneNumber) {
        matchData.patientPhoneNumber = $scope.tabs[tabKey].searchFormQuery.patientPhoneNumber
          .replace(/-/g, '')
          .replace(' ', '')
          .replace(/_/g, '')
          .replace('(', '')
          .replace(')', '');
      }

      if ($scope.tabs[tabKey].searchFormQuery.rideType) {
        if ($scope.tabs[tabKey].searchFormQuery.rideType === 'Vendor Rides') {
          matchData.is_pr_ride = false;
        } else {
          matchData.is_pr_ride = true;
        }
      }

      if (
        $scope.tabs[tabKey].searchFormQuery.dateRange
        && $scope.tabs[tabKey].searchFormQuery.dateRange.startDate
        && $scope.tabs[tabKey].searchFormQuery.dateRange.endDate
      ) {
        const firstDate = new Date($scope.tabs[tabKey].searchFormQuery.dateRange.startDate);
        const dateTime = moment(firstDate).format('YYYY-MM-DD HH:mm:ss');
        const startDate = dateTime;


        const secondDate = new Date($scope.tabs[tabKey].searchFormQuery.dateRange.endDate);
        const endDateTime = moment(secondDate).format('YYYY-MM-DD HH:mm:ss');
        const endDate = endDateTime;


        matchData.dateRange = {
          startDate,
          endDate,
        };
      }

      if (tabKey === 'pr') {
        matchData.is_pr_ride = true;
      } else if (tabKey === 'rides') {
        matchData.is_pr_ride = false;
      } else if (tabKey === 'variance') {
        matchData.is_variance = true;
      }

      if ($scope.tabs[tabKey].searchFormQuery.userList) {
        matchData.userList = $scope.tabs[tabKey].searchFormQuery.userList;
      }
      if ($scope.tabs[tabKey].searchFormQuery.providerName) {
        matchData.providerName = $scope.tabs[tabKey].searchFormQuery.providerName;
      }
      if ($scope.tabs[tabKey].searchFormQuery.providerId) {
        matchData.providerId = $scope.tabs[tabKey].searchFormQuery.providerId;
      }
      if ($scope.tabs[tabKey].searchFormQuery.requestingOrganization) {
        matchData.requestingOrganization = {
          id: $scope.tabs[tabKey].searchFormQuery.requestingOrganization.id,
          name: $scope.tabs[tabKey].searchFormQuery.requestingOrganization.name,
        };
      }
      matchData.orderId = $scope.tabs[tabKey].searchFormQuery.orderId || null;
      matchData.tripIdentifierType = $scope.tabs[tabKey].searchFormQuery.tripIdentifierType || null;
      matchData.masInvoiceNumber = $scope.tabs[tabKey].searchFormQuery.masInvoiceNumber || null;
      return angular.copy(matchData);
    }

    function setUrlFilters(tabKey, filters) {
      if ($scope.tabs[tabKey].active) {
        $location.search('activeTab', tabKey);
        Object.keys(filters).forEach((key) => {
          if (key === 'dateRange') {
            $location.search('startDate', filters.dateRange.startDate);
            $location.search('endDate', filters.dateRange.endDate);
          } else {
            $location.search(key, filters[key]);
          }
        });
        $location.search('currentPage', $scope.tabs[tabKey].pagination.currentPage);
      }
      return filters;
    }

    function adjustPagination(tabKey) {
      $scope.tabs[tabKey].pagination.lastItem = ($scope.tabs[tabKey].pagination.currentPage - 1) * $scope.tabs[tabKey].pagination.itemsPerPage + 20;
      if ($scope.tabs[tabKey].pagination.lastItem > $scope.tabs[tabKey].pagination.totalItems) {
        $scope.tabs[tabKey].pagination.lastItem = $scope.tabs[tabKey].pagination.totalItems;
      }
    }

    function getCompletedAppointments(tabKey, filters) {
      const url = `${API_BASE_URL + $scope.tabs[tabKey].url + ($scope.tabs[tabKey].pagination.currentPage - 1) * $scope.tabs[tabKey].pagination.itemsPerPage}/${
        $scope.tabs[tabKey].pagination.itemsPerPage
      }`;

      $scope.tabs[tabKey].showLoader = true;
      loadingScreenFactory.showLoadingScreen();
      Object.assign(filters, {
        org_id: userDetails.organisation.id,
      });

      const getCompletedAppointmentsCall = $http({
        url,
        method: 'GET',
        params: filters,
      });

      getCompletedAppointmentsCall.then(
        (result) => {
          if (result.data.success) {
            loadingScreenFactory.hideLoadingScreen();
            $scope.tabs[tabKey].data = result.data.data;
            $scope.tabs[tabKey].pagination.totalItems = result.data.totalCount;
            if ($stateParams.currentPage && $scope.tabs[tabKey].firstTimeLoad && $scope.tabs[tabKey].active) {
              $scope.tabs[tabKey].pagination.currentPage = $stateParams.currentPage;
            }
            adjustPagination(tabKey);
            $scope.tabs[tabKey].firstTimeLoad = false;
            $scope.tabs[tabKey].showLoader = false;
            // showOrHideLoader();
          }
        },
        (err) => {
          loadingScreenFactory.hideLoadingScreen();
          $scope.tabs[tabKey].data = [];
          $scope.tabs[tabKey].pagination.totalItems = 0;
          adjustPagination(tabKey);
          if (!$scope.tabs[tabKey].firstTimeLoad && err.status) {
            toaster.pop({
              type: 'error',
              title: err.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          }
          $scope.tabs[tabKey].firstTimeLoad = false;
        },
      );
    }

    function filterCompletedAppts(tabKeyIn, isSearch = false) {
      if ($scope.tabs[tabKeyIn].active) {
        $location.url($location.path());
      }

      const matchData = setSearchFilters(tabKeyIn);
      if (isSearch) {
        const matchDataCopy = angular.copy(matchData);
        matchDataCopy.userList = $scope.tabs[tabKeyIn].completed.userList;
        storage.setDataByKey('keepFilterData', currentState, { tab: tabKeyIn, data: matchDataCopy, currentPage: $scope.tabs[tabKeyIn].pagination.currentPage });
      }
      if (matchData.requestingOrganization) {
        Object.assign(matchData, {
          requestingOrganizationId: matchData.requestingOrganization.id,
          requestingOrganization: undefined,
        });
      }
      if (matchData.organization) {
        Object.assign(matchData, {
          organizationId: matchData.organization.id,
          organization: undefined,
        });
      }

      setUrlFilters(tabKeyIn, matchData);
      getCompletedAppointments(tabKeyIn, matchData);
    }

    $scope.searchButtonClicked = function searchButtonClicked(tabKey) {
      if (
        $scope.tabs[tabKey].completed.providerName && $scope.tabs[tabKey].completed.providerName.name && $scope.tabs[tabKey].completed.providerName.name.trim()
      ) {
        $scope.tabs[tabKey].searchFormQuery.providerName = $scope.tabs[
          tabKey
        ].completed.providerName.name;
        if ($scope.tabs[tabKey].completed.providerName.provider_codes) {
          $scope.tabs[tabKey].searchFormQuery.providerId = $scope.tabs[
            tabKey
          ].completed.providerName.provider_codes.join();
        } else {
          $scope.tabs[tabKey].searchFormQuery.providerId =            $scope.tabs[tabKey].completed.providerName.providerId;
        }
      } else {
        $scope.tabs[tabKey].searchFormQuery.providerName = null;
      }
      if ($scope.tabs[tabKey].completed.organization) {
        $scope.tabs[tabKey].searchFormQuery.organization = {
          name: $scope.tabs[tabKey].completed.organization.name,
          id: $scope.tabs[tabKey].completed.organization.id,
        };
      }

      if ($scope.tabs[tabKey].completed.patientName) {
        if ($scope.tabs[tabKey].completed.patientName.trim()) {
          $scope.tabs[tabKey].searchFormQuery.patientName = $scope.tabs[tabKey].completed.patientName.trim();
        }
      } else {
        $scope.tabs[tabKey].searchFormQuery.patientName = null;
      }

      if ($scope.tabs[tabKey].completed.status) {
        $scope.tabs[tabKey].searchFormQuery.status = $scope.tabs[tabKey].completed.status;
      } else {
        $scope.tabs[tabKey].searchFormQuery.status = null;
      }

      if ($scope.tabs[tabKey].completed.invoiceStatus) {
        $scope.tabs[tabKey].searchFormQuery.invoiceStatus = $scope.tabs[tabKey].completed.invoiceStatus;
      } else {
        $scope.tabs[tabKey].searchFormQuery.invoiceStatus = null;
      }

      if ($scope.tabs[tabKey].completed.appointmentType) {
        $scope.tabs[tabKey].searchFormQuery.appointmentType = $scope.tabs[tabKey].completed.appointmentType;
      } else {
        $scope.tabs[tabKey].searchFormQuery.appointmentType = null;
      }

      if ($scope.tabs[tabKey].completed.referenceNo) {
        $scope.tabs[tabKey].searchFormQuery.referenceNo = $scope.tabs[tabKey].completed.referenceNo;
      } else {
        $scope.tabs[tabKey].searchFormQuery.referenceNo = null;
      }

      if ($scope.tabs[tabKey].completed.patientPhoneNumber) {
        $scope.tabs[tabKey].searchFormQuery.patientPhoneNumber = $scope.tabs[tabKey].completed.patientPhoneNumber
          .replace(/-/g, '')
          .replace(' ', '')
          .replace(/_/g, '')
          .replace('(', '')
          .replace(')', '');
      } else {
        $scope.tabs[tabKey].searchFormQuery.patientPhoneNumber = null;
      }

      if ($scope.tabs[tabKey].completed.rideType) {
        $scope.tabs[tabKey].searchFormQuery.rideType = $scope.tabs[tabKey].completed.rideType;
      } else {
        $scope.tabs[tabKey].searchFormQuery.rideType = null;
      }

      if ($scope.tabs[tabKey].completed.market_segment_id) {
        $scope.tabs[tabKey].searchFormQuery.market_segment_id = $scope.tabs[tabKey].completed.market_segment_id;
      } else {
        $scope.tabs[tabKey].searchFormQuery.market_segment_id = null;
      }

      if ($scope.tabs[tabKey].completed.dateRange && $scope.tabs[tabKey].completed.dateRange.startDate && $scope.tabs[tabKey].completed.dateRange.endDate) {
        if (!$scope.tabs[tabKey].searchFormQuery.dateRange) $scope.tabs[tabKey].searchFormQuery.dateRange = {};
        $scope.tabs[tabKey].searchFormQuery.dateRange.startDate = $scope.tabs[tabKey].completed.dateRange.startDate;
        $scope.tabs[tabKey].searchFormQuery.dateRange.endDate = $scope.tabs[tabKey].completed.dateRange.endDate;
      } else {
        $scope.tabs[tabKey].searchFormQuery.dateRange = {
          startDate: null,
          endDate: null,
        };
      }

      if ($scope.tabs[tabKey].completed.userList) {
        let userListString = '';
        const { userList } = $scope.tabs[tabKey].completed;
        const userListLength = userList.length;
        userList.forEach((user, index) => {
          if (index === userListLength - 1) {
            userListString += user.userId;
          } else {
            userListString += `${user.userId},`;
          }
        });
        $scope.tabs[tabKey].searchFormQuery.userList = userListString;
      }
      if ($scope.tabs[tabKey].completed.requestingOrganization) {
        $scope.tabs[tabKey].searchFormQuery.requestingOrganization = {
          id: $scope.tabs[tabKey].completed.requestingOrganization.id,
          name: $scope.tabs[tabKey].completed.requestingOrganization.name,
        };
      }

      $scope.tabs[tabKey].searchFormQuery.orderId = $scope.tabs[tabKey].completed.orderId || null;
      $scope.tabs[tabKey].searchFormQuery.masInvoiceNumber = $scope.tabs[tabKey].completed.masInvoiceNumber || null;
      $scope.tabs[tabKey].searchFormQuery.tripIdentifierType = $scope.tabs[tabKey].completed.tripIdentifierType || null;
      filterCompletedAppts(tabKey, true);
    };

    $scope.pageChanged = function pageChanged(tabKey) {
      if (!$scope.tabs[tabKey].firstTimeLoad) {
        filterCompletedAppts(tabKey, true);
      }
    };

    $scope.tripIdentifierChanged = function tripIdentifierChanged(tabKey) {
      $scope.tabs[tabKey].completed.orderId = null;
      $scope.tabs[tabKey].completed.masInvoiceNumber = null;
      $scope.tabs[tabKey].completed.referenceNo = null;
    };

    $scope.loadMoreChoices = () => {
      $scope.organizations.visibleChoices += visibleChoicesCount;
    };

    $scope.loadMoreChoicesForRequestingOrganizations = () => {
      $scope.requestingOrganizations.visibleChoices += visibleChoicesCount;
    };
    const getAllOrganizations = async () => {
      const params = {
        fields: ['orgId', 'orgName'],
        displayOrgChildren: true,
      };
      if (
        userDetails.organisation.is_third_party
        && [ROLES.ORG_SUPERADMIN, ROLES.ORG_ADMIN].includes(userDetails.role)
      ) {
        params.getDelegatingOrgs = true;
      }
      if (userDetails.belongsToParentOrg) {
        params.orgId = userDetails.organisation.id;
      }
      try {
        const { data: { organisations: organizations } } = await $http.get(`${API_BASE_URL}organisations`, {
          params,
        });
        $scope.organizations = {
          data: organizations,
          visibleChoices: visibleChoicesCount,
        };
      } catch (error) {
        toaster.pop({
          type: 'error',
          title: error.message || 'Unable to fetch Organizations',
          showCloseButton: true,
          timeout: 10000,
        });
      }
    };

    const getRequestingOrganizations = async (organizationId) => {
      try {
        const { data: { organisations: requestingOrganizations } } = await requestingOrganizationFactory.getRequestingOrganizations({
          organizationId,
          fields: ['orgId', 'orgName'],
        });
        $scope.requestingOrganizations = {
          data: requestingOrganizations,
          visibleChoices: requestingOrganizationsVisibleCount,
        };
      } catch (error) {
        toaster.pop({
          type: 'error',
          title: error.message || 'Unable to fetch Requesting Organizations',
          showCloseButton: true,
          timeout: 10000,
        });
      } finally {
        $scope.$apply();
      }
    };

    $scope.clearFilters = function clearFilters(tabKey) {
      const prevSearchValues = storage.getDataByKey('keepFilterData', currentState);
      if (prevSearchValues && prevSearchValues.tab === tabKey) {
        storage.removeDataByKey('keepFilterData', currentState);
      }

      $scope.tabs[tabKey].searchFormQuery = {
        dateRange: { startDate: null, endDate: null },
      };
      $scope.tabs[tabKey].completed = {
        dateRange: { startDate: null, endDate: null },
        userList: [],
        organization: $scope.tabs[tabKey].completed.organization,
        patientName: '',
        orderId: '',
        masInvoiceNumber: null,
        tripIdentifierType: null,
        claimNumber: null,
        providerName: undefined,
      };
      $scope.tabs[tabKey].pagination.currentPage = 1;
      if (!$scope.disableOrgList) {
        delete $scope.tabs[tabKey].completed.organization;
      }
      getRequestingOrganizations();
      $location.url($location.path());
      filterCompletedAppts(tabKey);
    };

    $scope.orgChanged = function orgChanged(orgId, tabName, notEmptyUserList) {
      if (!orgId) {
        $scope.tabs[tabName].completed.userList = [];
      } else {
        if (!notEmptyUserList) {
          $scope.tabs[tabName].completed.userList = [];
        }
        $scope.tabs[tabName].reloadRequesters = true;
      }
      getRequestingOrganizations(orgId);
      $scope.tabs[tabName].completed.requestingOrganization = undefined;
    };

    getAllOrganizations();
    getRequestingOrganizations();

    let isInited = false;
    function init() {
      if (!isInited) {
        isInited = true;
        Object.keys($scope.tabs).some((tab) => {
          if ($scope.tabs[tab].active) {
            writeSearchFilter(tab);
            return true;
          }
          return false;
        });
      }
    }
    init();
    let userSettings;
    function getUserSettings() {
      const headers = {
        username: userDetails.username,
        usertoken: userDetails.user_token,
        'Content-Type': 'application/json',
      };
      const request = $http.get(`${API_BASE_URL}user/settings/`, { headers });

      request.then(({ data: objSuccess }) => {
        userSettings = objSuccess.data;
        if (userSettings.organisation.pr_status) {
          if (userSettings.settings.admin_pr) {
            $scope.tabs.pr.show = true;
          } else {
            $scope.tabs.pr.show = false;
          }
        } else {
          $scope.tabs.pr.show = false;
        }

        if (userSettings.organisation.customizable_file_number) {
          $scope.fileNumberLabel = userSettings.organisation.file_number_label;
        }
      });
    }
    getUserSettings();

    function listenForPubnubMessages() {
      $rootScope.$on(Pubnub.getMessageEventNameFor(userDetails.user_id), (event, payload) => {
        if (payload.message.type === 'fileReady' && payload.message.page === 'Completed Appointments' && $state.current.name === 'main.dashboard.completed') {
          getLatestProcessedExportRecords();
        }
      });
    }
    listenForPubnubMessages();


    $scope.tabClicked = function tabClicked(tabKey) {
      $location.url($location.path());
      const matchData = setSearchFilters(tabKey);
      setUrlFilters(tabKey, matchData);
      $location.search('activeTab', tabKey);
      // TODO: hotfix to fix something broken by init() function code...need to fix properly
      $scope.searchButtonClicked(tabKey);
      $scope.organisations.visibleChoices = visibleChoicesCount;
    };

    $scope.openInvoicePage = function openInvoicePage(appt, key) {
      if (appt.appointment_status !== 'Appointment Rejected') {
        if (
          [
            'Appointment Completed',
            'Appointment Canceled',
            'Pending Submission',
            'Patient Deleted',
            'Patient No Show',
            rideStatus.RETRO_QUEUED.status,
            rideStatus.VALIDATION_FAILED.status,
            rideStatus.PENDING_DISPOSITION.status,
          ].includes(appt.appointment_status)
        ) {
          const params = {
            appt_id: appt.id,
          };

          if (key === 'variance') {
            params.variance = true;
          }
          $state.go('main.dashboard.invoice', params);
        } else if (appt.invoice_generated) {
          $state.go('main.dashboard.invoice', {
            appt_id: appt.id,
          });
        } else if (appt.ride_details && appt.ride_details.length > 0) {
          let anyRideComplete = false;
          let managedBy;

          appt.ride_details.forEach((ride) => {
            if (ride.ride_status === 'Ride Completed') {
              anyRideComplete = true;
              return true;
            }
            managedBy = ride.user
              ? `${ride.user.first_name} ${ride.user.last_name}`
              : 'admin';
            return false;
          });

          if (anyRideComplete) {
            SweetAlert.swal({
              title: 'Invoice Generation Pending',
              text: 'Invoice will be generated soon',
              type: 'warning',
              confirmButtonClass: 'btn-warning',
              confirmButtonText: 'Ok',
              closeOnConfirm: true,
            });
          } else {
            SweetAlert.swal({
              title: 'Trip Cancelled',
              text: `Trip was cancelled at${$filter('date')(new Date(appt.updatedAt), 'dd/MM/yyyy hh:mm a')} by ${managedBy} and no ride was dispatched`,
              type: 'warning',
              confirmButtonClass: 'btn-warning',
              confirmButtonText: 'Ok',
              closeOnConfirm: true,
            });
          }
        }
      } else {
        SweetAlert.swal({
          title: 'No Invoice Available',
          text: 'Invoice not generated for rejected trips',
          type: 'warning',
          confirmButtonClass: 'btn-warning',
          confirmButtonText: 'Ok',
          closeOnConfirm: true,
        });
      }
    };


    $scope.enterOnFormSearch = function enterOnFormSearch(event, tabKey) {
      if (event.keyCode === 13) {
        if ($scope.tabs[tabKey].searchFormQuery.patientPhoneNumber && $scope.tabs[tabKey].searchFormQuery.patientPhoneNumber.charAt(0) === '_') {
          $scope.tabs[tabKey].searchFormQuery.patientPhoneNumber = undefined;
        }
        if ($scope.tabs[tabKey].completed.patientPhoneNumber && $scope.tabs[tabKey].completed.patientPhoneNumber.charAt(0) === '_') {
          $scope.tabs[tabKey].completed.patientPhoneNumber = undefined;
        }
        $scope.searchButtonClicked(tabKey);
      }
    };

    $scope.refreshCompletedAppointments = function refreshCompletedAppointments() {
      filterCompletedAppts();
    };

    let appointmentsToApprove = [];
    $scope.showApproveUI = false;

    $scope.showApproveButtonClicked = function showApproveButtonClicked() {
      $scope.showApproveUI = true;
    };

    $scope.addToApprove = function addToApprove(id) {
      if (appointmentsToApprove.indexOf(id) > -1) {
        const index = appointmentsToApprove.indexOf(id);
        appointmentsToApprove.splice(index, 1);
      } else {
        appointmentsToApprove.push(id);
      }
    };
    $scope.checkAllAppointments = false;
    $scope.addAllToApprove = function addAllToApprove(apptData) {
      if (!$scope.checkAllAppointments) {
        appointmentsToApprove = [];
        for (let i = 0; i < apptData.length; i += 1) {
          if (apptData[i].appointment_approved !== true) {
            appointmentsToApprove.push(apptData[i].id);
          }
        }
        $scope.checkAllAppointments = true;
      } else {
        appointmentsToApprove = [];
        $scope.checkAllAppointments = false;
      }
    };

    $scope.isSelectedAppt = function isSelectedAppt(id) {
      if (appointmentsToApprove.indexOf(id) > -1) {
        return true;
      }
      return false;
    };

    $scope.approveSelectedApoointments = function approveSelectedApoointments() {
      if (appointmentsToApprove.length < 1) {
        toaster.pop({
          type: 'error',
          title: 'No Trip Selected',
          showCloseButton: true,
          timeout: 10000,
        });
        return;
      }
      const urlToApproveAppointments = `${API_BASE_URL}appointment/approve`;
      const params = {
        appointmentsId: appointmentsToApprove,
      };
      const approveAppointmentsApiCall = $http.put(urlToApproveAppointments, params);

      approveAppointmentsApiCall.then(
        (result) => {
          if (result) {
            if (result.data.success) {
              SweetAlert.swal({
                title: 'Approved',
                text: 'Trips approved successfully',
                type: 'success',
              });
              appointmentsToApprove = [];
              $scope.showApproveUI = false;
              filterCompletedAppts('pr');
            } else {
              SweetAlert.swal({
                title: 'Failed',
                text: result.data.msg,
                type: 'error',
              });
            }
          }
        },
        (err) => {
          toaster.pop({
            type: 'error',
            title: err.data.msg,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    };
    /** IIFE to fetch provider names */
    (async () => {
      try {
        const { data: { providerNameCodeListing } } = await $http.get(`${API_BASE_URL}vendors/provider-names`);
        $scope.provider.providerNameList = providerNameCodeListing;
      } catch (err) {
        toasterError('Failed to get provider name list');
      }
    })();
    $scope.startsWith = (state, viewValue) => {
      if (viewValue.length < 3) {
        return undefined;
      }
      return state.substr(0, viewValue.length).toLowerCase() === viewValue.toLowerCase();
    };
  },
]);
